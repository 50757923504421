import {mapGetters, mapMutations, mapActions} from 'vuex';
import * as moment from "moment/moment";

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
                description: '',
                can_edit_orders: false,
                can_add_new_orders: false,
                can_send_sms: false,
                can_show_orders_without_department: false,
                hide_phones_in_orders: false,
                accept_to_menu_items: [
                    {
                        item_name: 'Контакты',
                        id: 0,
                        value: false,
                        type: 0
                    },
                    {
                        item_name: 'Группы пользователей',
                        id: 1,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Пользователи',
                        id: 2,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Отдел',
                        id: 3,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Групы клиентов',
                        id: 4,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Клиенты',
                        id: 5,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Заказы',
                        id: 6,
                        value: false,
                        type: 0
                    },
                    {
                        item_name: 'Статусы заказов',
                        id: 7,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Способы оплаты',
                        id: 8,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Перечень заказов',
                        id: 9,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Способы доставки',
                        id: 10,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Каталог',
                        id: 11,
                        value: false,
                        type: 0
                    },
                    {
                        item_name: 'Категории товаров',
                        id: 12,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Товары',
                        id: 13,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Производители',
                        id: 14,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Сайты (Лендинги)',
                        id: 15,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Материалы',
                        id: 16,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Модель',
                        id: 17,
                        value: false,
                        type: 1
                    },


                    {
                        item_name: 'Отправка товара',
                        id: 18,
                        value: false,
                        type: 0
                    },

                    {
                        item_name: 'Список для куръера',
                        id: 19,
                        value: false,
                        type: 1
                    },

                    {
                        item_name: 'Реестры НП',
                        id: 20,
                        value: false,
                        type: 1
                    },


                    {
                        item_name: 'Склад',
                        id: 21,
                        value: false,
                        type: 0
                    },
                    {
                        item_name: 'Cклады',
                        id: 22,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Остатки на складах',
                        id: 23,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Поставщики',
                        id: 24,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Приход товара',
                        id: 25,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Перемищение товара',
                        id: 26,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Шаблоны',
                        id: 27,
                        value: false,
                        type: 0
                    },
                    {
                        item_name: 'Смс шаблоны',
                        id: 28,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Модули',
                        id: 29,
                        value: false,
                        type: 0
                    },
                    {
                        item_name: 'Списки модулев',
                        id: 30,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Курс валюты',
                        id: 31,
                        value: false,
                        type: 0
                    },
                    {
                        item_name: 'Курс валюты',
                        id: 32,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Статистика',
                        id: 33,
                        value: false,
                        type: 0
                    },
                    {
                        item_name: 'Статистика (Заказы)',
                        id: 34,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Нехватка (Товары)',
                        id: 35,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Курс валюты',
                        id: 36,
                        value: false,
                        type: 0
                    },
                    {
                        item_name: 'Курс валюты',
                        id: 37,
                        value: false,
                        type: 1
                    },
                    {
                        item_name: 'Рассылка',
                        id: 38,
                        value: false,
                        type: 0
                    },
                    {
                        item_name: 'Sms рассылка',
                        id: 39,
                        value: false,
                        type: 1
                    },
                ],
                accept_to_statuses: []
            },
            checkedAllMenu: false,
            checkedAllStatuses: false,
        }
    },
    computed: {
        ...mapGetters({
            userGroup: 'contacts/userGroup',
            orderStatusList: 'orders/orderStatusList',
        }),
    },
    watch: {
        userGroup(e) {
            if (e) {
                let menuList = [];
                e.accept_to_menu_items.map((itemMenu, index) => {
                    menuList.push({
                        item_name: itemMenu.item_name,
                        id: itemMenu.id ? itemMenu.id : index,
                        value: Boolean(itemMenu.value),
                        type: Boolean(itemMenu.type),
                    })
                });
                this.form.title = e.title;
                this.form.description = e.description;
                this.form.can_edit_orders = Boolean(e.can_edit_orders);
                this.form.can_send_sms = Boolean(e.can_send_sms);
                this.form.hide_phones_in_orders = Boolean(e.hide_phones_in_orders);
                this.form.can_show_orders_without_department = Boolean(e.can_show_orders_without_department);
                this.form.can_add_new_orders = Boolean(e.can_add_new_orders);
                menuList.map(menuListItem => {
                    this.form.accept_to_menu_items.map(item => {
                        if (item.item_name === menuListItem.item_name) {
                            item.value = menuListItem.value
                        }
                    })
                })
            }
        },
        orderStatusList(e) {
            if (e && !this.userGroup) {
                e.data.map(item => {
                    this.form.accept_to_statuses.push({
                        color: item.color,
                        id: item.id,
                        title: item.title,
                        value: false,
                    })
                });
            } else if (e && this.userGroup) {
                let statusesList = [];
                e.data.map((item, index) => {
                    statusesList.push({
                        color: item.color ? item.color : '#000',
                        title: item.title ? item.title : index,
                        id: item.id ? item.id : index,
                        value: false
                    })
                })
                this.userGroup.accept_to_statuses.map(item => {
                    statusesList.map(itemMenu => {
                        if (item.id === itemMenu.id) {
                            itemMenu.value = Boolean(item.value)
                        }
                    })
                })

                this.form.accept_to_statuses = statusesList
            }
        }
    },
    created() {
        this.getOrderStatuses()
    },
    methods: {
        changeToggle() {
            const audio = new Audio(require('../../../../assets/sounds/BUTTON_SWITCH.mp3'))
            audio.play();
        },
        changeMenuValueToBoolean(item) {
            return Boolean(item)
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendChanges() {
            let menuItems = [];
            this.form.accept_to_menu_items.map(item => {
                menuItems.push({
                    item_name: item.item_name,
                    id: item.id,
                    value: Number(item.value),
                    type: Number(item.type),
                })
            })

            let statusesList = [];
            this.form.accept_to_statuses.map((itemMenu, index) => {
                statusesList.push({
                    color: itemMenu.color ? itemMenu.color : '#000',
                    title: itemMenu.title ? itemMenu.title : index,
                    id: itemMenu.id ? itemMenu.id : index,
                    value: Number(itemMenu.value)
                })
            })
            const payload = {
                id: this.userGroup ? this.userGroup.id : null,
                title: this.form.title,
                description: this.form.description,
                can_edit_orders: Number(this.form.can_edit_orders),
                can_send_sms: Number(this.form.can_send_sms),
                hide_phones_in_orders: Number(this.form.hide_phones_in_orders),
                can_show_orders_without_department: Number(this.form.can_show_orders_without_department),
                can_add_new_orders: Number(this.form.can_add_new_orders),
                accept_to_menu_items: menuItems,
                accept_to_statuses: statusesList,
            };
            if (this.userGroup) {
                this.$emit('changeCustomerGroup', payload)
            } else {
                this.$emit('sendCustomerGroup', payload)
            }
        },
        createdDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        checkAllMenu() {
            if (this.checkedAllMenu) {
                this.form.accept_to_menu_items.map(item => {
                    item.value = true;
                })
            } else {
                this.form.accept_to_menu_items.map(item => {
                    item.value = false;
                })
            }
        },
        checkAllStatuses() {
            if (this.checkedAllStatuses) {
                this.form.accept_to_statuses.map(item => {
                    item.value = true;
                })
            } else {
                this.form.accept_to_statuses.map(item => {
                    item.value = false;
                })
            }
        },
        ...mapActions({
            getOrderStatuses: 'orders/getOrderStatuses',
        }),
        ...mapMutations({
            changeUserGroup: 'contacts/changeUserGroup',
        })
    },
    destroyed() {
        this.changeUserGroup({models: null});
    }
}
